.entry-title {
    padding-top: 40px;
    padding-left: 30px;
    font-size: 18px;
    color: white;
}

.entry-line {
    position: fixed;
    top: 10px;
    left: 0;
    width: 100%;
    height: 5px;
    background: rgb(240, 240, 240);
    z-index: 99999;
    overflow: hidden;
}

.entry-dot {
    position: absolute;
    height: 100%;
    width: 5px;
    background: #98a4b4;
    animation: entry-dotleft 2s cubic-bezier(.72, .03, .75, .54) infinite;
}

.entry-dot1 {}

.entry-dot2 {
    animation-delay: .4s;
}

.entry-dot3 {
    animation-delay: .8s;
}

.entry-dot4 {
    animation-delay: 1.2s;
}

.entry-dot5 {
    animation-delay: 1.6s;
}

@keyframes entry-dotleft {
    0% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}

