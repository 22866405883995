.color-picker {
    width: 228px;

    display: flex;
    flex-wrap: wrap;
}

.color-picker__color {
    width: 15px;
    height: 15px;
    margin: 2px;

    cursor: pointer;
}

.color-picker__color:hover {
    border: 1px solid black;
}