h5.waybills-footer-heading {
    margin-top: 5px !important;
    margin-bottom: 3px;
}

.waybills-footer-text {
    padding-left: 1px;
    font-size: 10px;
    min-height: 65px;
}

.waybills-footer-text > div {
    line-height: 12px;
}
.bgn-host {
    background-image: url('/img/bgn5.png');
    background-size: cover;
    /* background-position-y: -250px; */
    background-repeat: no-repeat;
    background-attachment: fixed;
}


.step-wrapper {
    padding-top: 20px;
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    overflow-x: hidden;
}

.step-wrapper > .step {
    flex-wrap: nowrap!important;
}
.step-wrapper > .step:first-child {
    border-top: 1px solid #f3f4f5;
}
.step-wrapper > .step.active:first-child {
    border-top: 1px solid gainsboro;
}
.step-wrapper > .step.active:after {
    background: white !important;
}
.page-height-155 {
    height: calc(100vh - 155px);
}

.docs-modal__btn > .angle {
    margin-top: -2px !important;
    margin-right: 0;
}

.docs-modal__btn {
    cursor: pointer;
    padding: 10px 21px;
}
