.button-complete-wiz.ui.button.dropdown {
    border-left: 1px solid white !important;
    padding-top: 5px !important;
}

.icon-validation-negative {
    font-size: 27px;
    color: red;
    margin-left: 10px;
}

input.cargoCost {
    width: inherit;
    /* width: 90px; */
    text-align: right;
}

@media screen and (max-width: 1452pх) {
    input.cargoCost {
        width: 90px !important;
    }
}

.column.editor-rightcol {
    padding-left: 0 !important;
}

@media screen and (min-width: 1600px) {
    .column.editor-rightcol {
        width: 72% !important;
    }
}
.bottom-alert {
    display: absolute;
    bottom: 0;
}
/* .page-bgn {
    background-image: url('/img/bgn6.png') !important;
    background-size: cover !important;
} */

.table-unloads td {
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	padding-right: 0 !important;
}

.table-unloads tr {
	color: rgba(0,0,0,.4);
}

.points-block {
    margin: 0 !important;
}

.points-block .points-block__route_header {
    width: 100%;
    padding-bottom: 8px;
    height: 45px;
}

.points-block .points-block__route_header .wbPointCardShellHeader-item-center {
    width: 100% !important;
    margin-left: 5px;
    margin-top: 3px;
}

.points-block .column.grid > .row {
    width: 100% !important;
}

.points-block .column.grid {
    width: auto !important;
}

.points-block .column, .points-block__points .grid {
    height: max-content;
}

.points-block__points .divided:not(.point_min-h-400) > .column {
    min-height: 200px;
}

.points-block__points .point_min-h-400 > .column {
    min-height: 400px;
}

.points-block .row:first-child {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.points-block__routes {
    width: 100%;
    height: calc(100vh - 280px);
    overflow: auto;
}

.points-block__route {
    width: 100%;
}

.points-block__btn {
    position: absolute;
    right: 35px;
    z-index: 103;
}

.points-block__header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgba(34,36,38,.15) !important;
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
}

.points-block__header h3 {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.points-block__header button {
    margin-right: 0 !important;
}

.points-block__header_text {
    padding-bottom: 10px;
}

.points-block__points {
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 0 !important;
}

.points-block__change-point-btn.icon, .points-block__change-point-btn.button {
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.points-block__change-point_rotated.icon {
    top: calc(100% + 5px);
}

.points-block__change-routes-btn.button {
    top: calc(100% - 8px);
    width: max-content;
}

.points-block__delete {
    margin-left: auto !important;
}

.point-card-header__border {
    height: 47px;
    border-top: 1px solid rgba(34,36,38,.15) !important;
}

.point-card-header .f-r {
    height: 43px;
}

.point-card-header button {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
}

.point-card-header__border button {
    margin-top: 7px !important;
}

.point-card-header__first {
    position: sticky !important;
    height: 43px;
    top: 0;
    z-index: 102;
    background-color: white;
}

.point-input {
    margin-bottom: 10px !important;
}

.point-input .menu {
    max-height: 165px !important;
}

.point-input > .InputDropdown {
    width: 150px !important;
}

.history-modal {
    height: 90vh;
}

.history-modal__content {
    height: calc(90vh - 60px);
    overflow: auto;
}

.history-modal__header {
    text-align: center;
}