.form-rules {
    overflow: hidden;
}

.form-rules__header {
    display: grid;
    grid-template-columns: 80px 250px 250px 150px 1fr;
    font-weight: bold;
    font-size: 13px;
    background-color: #eeeeee;
    border-bottom: 1px solid rgba(34, 36, 38, .15);
}

.form-rules__body {
    overflow: auto;
}

.form-rules__row {
    display: grid;
    grid-template-columns: 80px 250px 1fr;
    font-size: 13px;
}

.form-rules__header > div, .form-rules__row > div {
    padding: 8px 10px;
}

.form-rules__header > div:last-child {
    padding: 4px 10px;
    width: 100%;
    text-align: right;
}

.rule-field .ui.dropdown > .text {
    margin-top: 0 !important;
}

.rule-field .ui.selection.dropdown .menu > .item {
    padding: 8px 13px !important;
}

.rule-field:not(:last-child) {
    margin-bottom: 10px;
}

.rule-field {
    display: grid;
    grid-template-columns: 250px 1fr;
}

.rule-field__rule {
    padding-right: 15px;
    width: 250px;
}

.rule-field input {
    min-height: 32px !important;
    height: 32px !important;
}

.add-btn {
    width: 200px;
    color: grey;
    cursor: pointer;
}

.del-btn {
    padding: 6px;
    font-size: 15px !important;
}

.rule-value__input {
    display: flex;
}

.rule-value__input .input, .splitters > div {
    width: 235px;
    margin-right: 10px;
}

.constant {
    display: grid;
    grid-template-columns: 235px 1fr;
}

.constant .ui.search.selection.dropdown > input.search {
    font-size: 12px;
}

.replace__row {
    display: grid;
    grid-template-columns: 235px 30px 235px 1fr;
}

.replace .rule-value__input > .rule-input > div:first-child {
    width: 300px !important;
}

.rule-open .rule-value__input > .rule-input input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rule-field .description {
    font-size: 12px;
}

.replace__row > i.icon {
    padding: 5px 10px;
}

.splitters {
    width: 266px !important;
}

.rule-input__info {
    font-size: 10px;
    color: rgba(128, 128, 128, 0.64);
    font-style: italic;
}

.custom-replace {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 250px 250px;
}

.custom-replace .field {
    padding-right: 15px;
}

.object-rows__block {
    padding-top: 10px;
    display: flex;
}

.object-rows__block .del-btn {
    margin-left: 10px;
}

.trim__row {
    display: grid;
    grid-template-columns: 250px 235px 1fr;
}

.trim__row .field:first-child {
    padding-right: 15px;
}

.trim .object-rows__block:first-child .del-btn {
    margin-top: 25px;
}

.trim__row .field {
    margin-bottom: 0 !important;
}

.indicator > span {
    font-size: 13px;
    margin-left: 5px;
}

.position {
    display: flex;
    margin-bottom: 15px;
}

@media screen and (max-width: 1495px) {
    .position {
        flex-wrap: wrap;
    }
}

.position > div:first-child {
    margin-right: 15px;
}

.position__input > input {
    font-size: 13px !important;
}

.info-example {
    display: inline-block;
    line-height: 13px;
}

.info-example > span:first-child, .info-example > span:last-child {
    color: #0f0f10;
    font-size: 8px;
    font-weight: bold;
    display: flex;
}

.info-example > span:first-child {
    justify-content: center;
}

.info-example > span:last-child {
    justify-content: space-between;
}

.info-example__text {
    text-decoration: underline;
    text-decoration-color: #91dea6;
}

.position__message {
    max-width: 485px;
    min-width: 310px;
}

.info-examples > div {
    display: flex;
}

.info-examples > div > span {
    margin-top: auto !important;
    margin-bottom: auto;
}

.info-examples > div > span:not(:first-child) {
    font-size: 16px;
}

.info-examples > div > span:first-child {
    margin-right: 6px;
}

.info-examples > div > span:nth-child(2) {
    margin-right: 3px;
}