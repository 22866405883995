.app-table-toolbar {
    align-items: center;
    justify-content: flex-end;

    background: white;
    padding-right: 5px;
    padding-left: 0;
    min-height: 53px;
    text-align: right;
}
.app-table-toolbar-left {
    margin-left: 0 !important;
    width: 500px;
    min-width: 500px;
}
.app-table-toolbar-right {
    flex-grow: 1;
    justify-content: flex-end;
}
.app-table-toolbar > * {
    margin-left: 1.2em;
}

.app-table-toolbar > .ui.input > input {
    font-size: 0.9em;
}
.app-table-toolbar .ui.input > i {
    color: #106ebe;
}