.dropzone {
    width: 100% !important;
    position: relative;
    height: 200px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
    padding: 80px;
    text-align: center;
    margin-top: 0;
}

.tabs-menu .menu {
    border: 0 !important;
}

.tabs-menu .content {
    padding: 12px 20px 20px !important;
}

.tabs-menu__content > .form {
    padding: 0 2px 20px;
}