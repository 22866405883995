.hover-cursor-move[draggable="true"]:hover {
    cursor: move;
}  
.height-245 {
    height:245px;
}

.grid-col-setup > .column {
    height: 290px;
}
.grid-col-setup-label.ui.label {
    margin: 5px;
    min-width: 180px;
}
.grid-col-setup-dropzone-active {
    border: 2px dashed grey;
}
.grid-col-setup-dropzone {
    border: 2px dashed white;
}
.grid-col-setup-subheader {
    float: right;
    padding-top: 4px !important;
}
.color-red {
    color: red !important;
}
.user-select-none {
    user-select: none;
}
.opacity-50 {
    opacity: .5;
}
