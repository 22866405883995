
.ui.menu.drawer2 {
    height: 100%;
    background-color: #0e4194 !important;
}
.item.drawer2-item {
    padding-top: 18px !important;
}
.item.drawer2-item > i.icon {
    /* color: #d4d4d5; */
    color: #bababca3;
}
.item.drawer2-item:hover > i.icon {
    color: white;
}
.item.drawer2-item.drawer2-item--selected > i.icon {
    color: white;
}