.cargo-totals {
    /* min-width: 525px; */
    display: inline-block !important;
}
.cargo-totals > div {
    margin-left: 40px !important;
    margin-right: 40px !important;
}
.cargo-totals-color-normal {
    color: #9E9E9E !important;
}
.cargo-totals-color-alert {
    color: #db2828 !important;
}