.ui.form.small .field>.ui.dropdown.selection {
    min-height: 33px !important;
    padding: 0 !important;
}

.ui.form .field.inline>.ui.dropdown.selection {
    vertical-align: middle;
}

.ui.form.small .field>.ui.dropdown.selection>.text {
    padding: 10px 1em;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin-bottom: 0;
    margin-top: 0 !important;
    line-height: 1em !important;
}
.ui.form.small .field>.ui.dropdown.selection>.text.default {
    font-size: 13px;
}

.InputDropdown.ui.dropdown>i.icon.dropdown {
    padding: 9px;
}

.InputDropdown.ui.dropdown.selection.loading>i.icon {
    margin-top: -15px;
    margin-right: -15px;
}

.InputDropdown.ui.dropdown {
    min-height: 38px !important;
    min-width: inherit !important;
}

.InputDropdown>a.ui.label {
    font-size: inherit !important;
}

.InputDropdown.ui.search.dropdown {
    /* opacity: 1; */
}

.InputDropdown.ui.dropdown.search.multiple>.default.text {
    top: inherit;
    min-height: 24px;
}

/* .InputDropdown.ui.dropdown > .default.text {
    font-size: 14px;
    min-height: 24px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    
} */

.InputDropdown.ui.dropdown>.text {
    font-size: 14px;
    min-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin-bottom: 0;
}

.InputDropdown.ui.dropdown.selection>.menu>.item>.text {
    font-size: 13px;
}