.app-button-link {
    /* font-size: 13px; */
    cursor: pointer;
    color: #4183c4;
    display: inline-block;
    font-weight: normal;
    /* text-decoration: underline; */
}
.app-button-link-disabled {
    pointer-events: none;
    color: #a2b6c9;
    display: inline-block;
    font-weight: normal;
}
.app-button-link:hover {
    color: #1e70bf;
}
.app-button-link[floated='right'], .app-button-link-disabled[floated='right'] {
    float: right;
}