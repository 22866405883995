.car-lable {
    width: 40px;
}
.car-outer {
    display: table;
    height: 40px;
    overflow: hidden;
    text-align: center;
    width: 210px;
}
.car-inner {
    display: table-cell;
    vertical-align: left;
    line-height: 13px;
    text-transform: uppercase;
}
.car-text {
    color:black;
    font-size: 11px;
    text-align: center;
}
.point-font {
    font-family: Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 12px;
}

.point-date {
    display: table;
    height: 40px;
    overflow: hidden;
    text-align: center;
    font-family: Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 12px;
}
.point-date > div:first-child {
    margin-bottom: -4px;
}
