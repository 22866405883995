.react-dadata__container {
  position: relative;
}

.react-dadata__input {
  display: block;
  box-sizing: border-box;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
}

.react-dadata__input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 154, 0, 0.1);
  border-color: #FF931E;
}

.react-dadata__suggestions {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 1px 6px 3px rgba(0, 0, 0, .1);
  top: 100%;
  left: 0;
  right: 0;
}

.react-dadata__suggestion-note {
  font-size: 14px;
  color: #828282;
  padding: 10px 10px 5px 10px;
}

.react-dadata__suggestion {
  font-size: 15px;
  padding: 7px 10px;
  cursor: pointer;
}

.react-dadata__suggestion--current {
  background-color: #FFF5E7;
}

.react-dadata__suggestion:hover {
  background-color: #FFF9F0;
}

.react-dadata mark {
  background: none;
}

.react-dadata--highlighted {
  color: #E5841C;
}