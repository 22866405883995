.points-table {
    border-bottom: 1px solid rgba(34, 36, 38, .1);
    height: calc(100vh - 218px);
}

.point-form-link-style {
    display: block;
    line-height: 30px;
    margin: -5px;
    padding-left: 5px;
    color: inherit;
}

.point-form-header-cell {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid rgba(34,36,38,.1);
    font-size: 14px;
    padding: 9px 10px;
}

.table-scroll.point-form-table {
    height: 230px;
    overflow-y: auto;
    border-bottom: 1px solid rgba(34,36,38,.1);
    border-top: 1px solid rgba(34,36,38,.1);
}

.point-header {
    margin-bottom: auto;
    margin-top: auto;
}
