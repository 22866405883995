.login-bgn {
    height: 100%;
    background-image: url('/img/bgn.jpg');
    background-size:cover;
    height: 100vh;
}
.login-wrapper {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.login-segment {
    border-radius: 0 !important;
    width: 450px;
    padding-bottom: 25px !important;
}
.login-segment-transparent {
    background: rgba(0,0,0,0.3) !important;
    /* color: white; */
    /* background: rgba(255,255,255,1) !important; */
    color: black;
}
.login-logo {
    text-align: center;
    padding-top: 5px;
}
.login-logo-transparent {
    background-color: white;
    margin: 15px;
    opacity: .9 !important;
    margin-top:30px;
    padding-top: 10px;
    padding-bottom: 5px;
}
.login-descr {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 5px;
    font-weight: bold;
    color: white;
}
.login-form {
    padding: 35px 15px 5px 15px;
}
.login-center {
    display: flex;
	justify-content: center;
	align-items: center;
}
.login-buttons {
    padding: 15px 15px 5px 15px;
    vertical-align: baseline;
}
.login-links {
    padding-top:20px;
}
.login-links a {
    color:white !important;
}
.login-error {
    padding: 5px 10px 5px 10px;
    min-height: 31px;
	font-size: smaller;
	background: #fbbd08;
	background: #db2828;
	color: white;
    /* color: red; */
}

