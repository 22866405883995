.ui.labeled.icon.button.dropdown-actionmenu {
    /* background: rgb(33, 133, 208); */
    /* color: white; */
    min-height: 38px !important;
    padding-bottom: 6px !important;
}
.ui.labeled.icon.button.dropdown-actionmenu > .text {
    font-family: Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 1em;
    font-weight: 700
}

