.app-toolbar-button {
    font-size: 0.9em;
    cursor: pointer;
    /* color: #4183c4; */
    display: inline-block;
    height: 34px;
    line-height: 34px;
}
.app-toolbar-button:hover {
    color: #1e70bf;
}
.app-toolbar-button[floated='right'] {
    float: right;
}
.app-toolbar-button > i {
    color: #4183c4;
}
.app-toolbar-button > i:hover {
    color: #1e70bf;
}
.app-toolbar-button--disabled {
    pointer-events: none;
    color: rgba(40,40,40,.3);
}