.wbPointCardShellHeader-container {
    display: flex;
    align-items: center;
}

.wbPointCardShellHeader-item-left {
    width: 50px;
}

.wbPointCardShellHeader-item-center {
    width: 100px;
    flex-grow: 1;
}

.wbPointCardShellHeader-item-right {
    width: 50px;
}