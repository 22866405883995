.noXButton::-ms-clear {
    width : 0;
    height: 0;
}
/* input::-webkit-clear-button, input::-ms-clear {
    display: none;
} */

.filter-flex-container {
    display: flex;

    /* make main axis horisontal*/
    flex-direction: row;
    flex-wrap: nowrap;

    /* center items horisontal, in this case */
    justify-content: space-between;

    /* center items horizontally, in this case */
    align-items: stretch;

    align-content: center;
    height: 100%;

    background-color: white;
}

.filter-flex-center.ui.input > input {
    padding-right: 0;
}

.filter-flex-left {
    width:28px;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 35px;
    text-align: center;
    font-size: 13px;
}
.filter-flex-center {
    flex-grow: 1;
    line-height: 35px;
    text-align: center;
    display:block;
    background: none;
}
.filter-flex-right {
    width:28px;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
}
.filter-select {
    padding-left: 5px;
}
.filter-date-input {
    -webkit-appearance: none;
    -webkit-user-modify: read-write-plaintext-only;
    width: 100%;
    height: 100%;
    outline: 0;
    border: 0 !important;
    box-sizing: border-box;
    user-select: text;
}
.filter-date-input::-ms-clear {
    display: none;
}
.filter-date-input i.icon {
    padding: 7px !important;
}
.filter-flex-left-hoverable:hover {
    cursor: pointer;
    background-color: #F9FAFB;
}
/* .filter-flex-left:not(.filter-flex-left-hoverable) {
    color: grey;
} */
.filter-flex-right:not(.filter-flex-right-active):hover {
    background-color: #F9FAFB;
}
.filter-flex-right-active:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.filter-date-period {
    width: max-content!important;
}
