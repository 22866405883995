#root {
    height: 100%;
}
.pages-container {
    height: 100%;
    min-height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
}
.pages-bar-left {
    background: blue;
    width: 60px;
    min-width: 60px;
}
.pages-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: calc(100vw - 60px);
}
.pages-content-top {
    height: 50px;
    min-height: 50px;
    /* margin-left: -60px; */

    /* background-color: #f3f4f5 !important; */
}
.notification {
    position: absolute;
    top: 15px;
    right: 21px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
}
.pages-content-top menu {
    border-top: none;
    border-left: none;
    border-right: none;
}
.pages-content-body {
    flex-grow: 1;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    background-color: #f3f4f5 !important;
}
.pages-content-footer {
    background: #d4d4d5;
    height: 37px;
    min-height: 37px;
}

.scroll-menu {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: calc(100vh - 98px);
    scroll-behavior: smooth;
}

.autocomplete-history {
    background-color: white;
    padding: 30px;
    height: calc(100vh - 130px);
    overflow: auto;
}

.calc-search {
    margin: 6px !important;
    width: 300px;
}

.calculating-fields-rules__table {
    margin-top: 15px;
    max-height: calc(100vh - 267px);
    overflow: auto;
    border-bottom: 1px solid rgba(34,36,38,.1);
    border-top: 1px solid rgba(34,36,38,.1);
}

.calculating-fields-rules__table thead {
    position: sticky;
    top: 0;
    z-index: 2;
}

.calculating-fields-rules__table table {
    border-top: 0 !important;
    border-bottom: 0 !important;
}
