.entry-outdated-message-container {
    padding-top: 70px;
    padding-left: 50px;
    padding-right: 50px;
}

.entry-outdated-message.ui.black.message {
    background: rgba(60, 60, 60, 0.7);
    max-width: 700px;
    white-space: normal;
    padding-top: 20px;
    padding-bottom: 20px;
}