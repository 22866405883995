/* .ui.grey.menu a.active.item.header-item {
    color: black;
    font-weight: bold;
} */

.app-header {
    /* background: none !important; */
    /* background-image: url('/img/header3.jpg'); */
    /* background-size: cover; */
    border-top: #eeeeee;
    background-color: #eeeeee !important;
    height: 50px;
}
.app-header .ui.link.menu .item:hover, .ui.menu .dropdown.item:hover, .ui.menu .link.item:hover, .ui.menu a.item:hover {
    background: rgba(0,0,0,.08);
    color: rgba(0,0,0,.95);
}

.pushable>.pusher {
    background: white;
}

.navbar-tab {
    font-size: 16px !important;
    border: 0 !important;
    box-shadow: none !important;
}

.navbar-tab .item {
    background: #eeeeee !important;
    border-right: 0 !important;
}

.navbar-tab .item:before {
    content: '';
}

.navbar-tab .active.item {
    background: white !important;
    border-right: 1px solid #d4d4d5 !important;
    border-left: 1px solid #d4d4d5 !important;
}
