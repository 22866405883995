@media only screen and (min-width: 1200px) {
    .ui.container.container-point-view {
        /* width: 1127px; */
        width: 1160px;
        margin-left: auto!important;
        margin-right: auto!important;
    }
}

@media only screen and (min-width: 1241px) {
    .right-gutter {
        max-width: 1241px;
    }
}